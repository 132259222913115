<template>
  <div
    class="fd-validation assign-developer-bank-accounts"
    :class="{ error: hasError }"
  >
    <!-- Selected Bank Account -->
    <collapsible
      v-model="isSelectedBankAccountShown"
      class="bordered-card p-1 mb-4"
      :class="{
        error: hasError,
        'shake-horizontal': hasError
      }"
    >
      <template #title>
        <h6 class="font-bold">
          Selected Bank Accounts
          <span class="count ml-1">{{ value.length }}</span>
        </h6>
      </template>
      <transition-group name="fade" class="row">
        <bank-account-selector
          v-for="bankAccount in value"
          :key="bankAccount.id"
          :bankAccount="bankAccount"
          :isSelected="true"
          @deselect="deselectBankAccount(bankAccount)"
          class="col-12 sm-col-6 md-col-4 lg-col-3 px-1 mb-2"
        ></bank-account-selector>
      </transition-group>
      <no-data
        v-if="value.length < 1"
        message="There is no selected bank account."
        class="w-100"
      ></no-data>
    </collapsible>
    <!-- Bank Account Selector -->
    <div class="row">
      <bank-account-selector
        v-for="bankAccount in data"
        :key="bankAccount.id"
        :bankAccount="bankAccount"
        :isSelected="
          value.filter((account) => account.id === bankAccount.id).length > 0
        "
        @select="selectBankAccount(bankAccount)"
        @deselect="deselectBankAccount(bankAccount)"
        class="col-12 sm-col-6 md-col-4 lg-col-3 px-1 mb-2"
      ></bank-account-selector>
    </div>
    <no-data
      v-if="data.length < 1"
      message="There is no bank account available. Please contact authorized admin to add bank account for this developer."
      class="w-100"
    ></no-data>
  </div>
</template>

<script>
import validator from "@/components/GlobalComponents/FormComponents/Validator/mixin/validatorMixin";

export default {
  components: {
    BankAccountSelector: () =>
      import(
        "@/components/GlobalComponents/Shared/Developer/DeveloperBankAccountCardSelector"
      ),
    Collapsible: () => import("@/components/GlobalComponents/Collapsible")
  },
  mixins: [validator],
  props: {
    value: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    validators: {
      type: Array
    }
  },
  data: function () {
    return {
      isSelectedBankAccountShown: false
    };
  },
  computed: {},
  watch: {
    value: {
      handler() {
        this.hasError = false;
      },
      deep: true
    },
    hasError(val) {
      if (val === true) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Assign Bank Account",
          text: `${this.errorMessage}`
        });
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    selectBankAccount(bankAccount) {
      this.$emit("input", [...this.value, bankAccount]);
    },
    deselectBankAccount(bankAccount) {
      this.$emit(
        "input",
        this.value.filter((account) => account.id !== bankAccount.id)
      );
    }
  }
};
</script>

<style lang="scss">
.assign-developer-bank-accounts {
  .count {
    background: $color-secondary;
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 14px;
  }

  .error {
    $errorColor: #db4141;
    border: solid 2px $errorColor;
  }
}
</style>
